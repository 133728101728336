<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list" >
      <div class="app-fixed-search d-flex align-items-center">
        <div
          v-if="$store.getters['user/getUserDetails'].user_type !== 'student'"
          class="sidebar-toggle d-block d-lg-none ml-1"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Search announcements"
              @input="searchAnnouncements"
            />
            {{ search_announcements }}
          </b-input-group>
        </div>
      </div>

      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <b-media
            v-for="(email, index) in emails"
            :key="index + '_email'"
            tag="li"
            no-body
            :class="{ 'mail-read': email.isRead }"
            @click="openEmail(email)"
          >
            <b-media-aside class="media-left mr-50">
              <b-avatar
                class="avatar"
                size="40"
                variant="primary"
                :src="FILESURL + email.user_profile_image"
              />
            </b-media-aside>

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h5 class="mb-25">
                    {{ email.from_name }}
                  </h5>
                  <span class="text-truncate">{{ email.subject }}</span>
                </div>
                <div class="mail-meta-item">
                  <span class="mail-date">{{
                    formatDateToMonthShort(email.created_at, {
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}</span>
                </div>
              </div>

              <div class="mail-message">
                <p class="text-truncate mb-0">
                  {{ filterTags(email.message) }}
                </p>
              </div>
            </b-media-body>
          </b-media>
        </ul>
        <div class="no-results" :class="{ show: !emails.length }">
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <email-view
      :class="{ show: showEmailDetails }"
      :email-view-data="emailViewData"
      :opended-email-meta="opendedEmailMeta"
      @close-email-view="showEmailDetails = false"
    />

    <div v-if="$store.getters['user/getUserDetails'].user_type !== 'student'">
      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-left">
        <email-left-sidebar
          :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
          :emails-meta="emailsMeta"
          :class="{ show: mqShallShowLeftSidebar }"
          @close-left-sidebar="mqShallShowLeftSidebar = false"
          @filter-selected="filterSelected"
        />
      </portal>

      <!-- Compose Email Modal -->
      <email-compose
        v-model="shallShowEmailComposeModal"
        :reload-list="getAllAnnouncements"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { ref, onUnmounted, computed, watch } from "@vue/composition-api";
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { filterTags, formatDateToMonthShort } from "@core/utils/filter";
import { useRouter } from "@core/utils/utils";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import EmailLeftSidebar from "./EmailLeftSidebar.vue";
import EmailView from "./EmailView.vue";
import emailStoreModule from "./emailStoreModule";
import useEmail from "./useEmail";
import EmailCompose from "./EmailCompose.vue";
import { FILESURL } from "@/config";
import CommonServices from "@/apiServices/CommonServices";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    EmailLeftSidebar,
    EmailView,
    EmailCompose,
  },
  data() {
    return {
      emails: [],
      allEmail: [],
      FILESURL,
      search_announcements: "",
    };
  },

  setup() {
    const EMAIL_APP_STORE_MODULE_NAME = "app-email";

    // Register module
    if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME))
      store.registerModule(EMAIL_APP_STORE_MODULE_NAME, emailStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME))
        store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME);
    });

    const { route, router } = useRouter();
    const { resolveLabelColor } = useEmail();

    // Route Params
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchEmails();
    });

    // Emails & EmailsMeta
    const emails = ref([]);
    const emailsMeta = ref({});

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    // Search Query
    const routeQuery = computed(() => route.value.query.q);
    const searchQuery = ref(routeQuery.value);
    watch(routeQuery, (val) => {
      searchQuery.value = val;
    });
    // eslint-disable-next-line no-use-before-define
    watch(searchQuery, () => fetchEmails());
    const updateRouteQuery = (val) => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

      if (val) currentRouteQuery.q = val;
      else delete currentRouteQuery.q;

      router.replace({ name: route.name, query: currentRouteQuery });
    };

    // ------------------------------------------------
    // Email Details
    // ------------------------------------------------
    const showEmailDetails = ref(false);
    const emailViewData = ref({});
    const opendedEmailMeta = computed(() => {
      const openedEmailIndex = emails.value.findIndex(
        (e) => e.id === emailViewData.value.id
      );
      return {
        hasNextEmail: Boolean(emails.value[openedEmailIndex + 1]),
        hasPreviousEmail: Boolean(emails.value[openedEmailIndex - 1]),
      };
    });
    const openEmail = (email) => {
      // if (!email.isRead) {
      //   emailsMeta.value[email.folder] -= 1;
      // }
      // email.isRead = true;
      emailViewData.value = email;
      showEmailDetails.value = true;
    };

    // Compose
    const shallShowEmailComposeModal = ref(false);

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      // UI
      perfectScrollbarSettings,

      // Emails & EmailsMeta
      emailsMeta,

      // Email Details
      showEmailDetails,
      emailViewData,
      opendedEmailMeta,
      openEmail,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
    };
  },
  methods: {
    filterSelected(filter) {
      switch (filter) {
        case "inbox":
          this.getAllAnnouncements();
          break;

        case "sent":
          this.filterSentAnnouncements();
          break;

        default:
          break;
      }
    },
    filterSentAnnouncements() {
      const user_email = this.$store.getters["user/getUserDetails"].user_email;
      const sentAnnouncements = this.emails.filter((email) => {
        return email.user_email === user_email;
      });
      this.emails = sentAnnouncements;
    },
    searchAnnouncements(e) {
      this.getAllAnnouncements({ search: e });
    },
    getAllAnnouncements(payload) {
      CommonServices.getAllAnnouncement(payload)
        .then((response) => {
          if (response.data.status) {
            this.emails = response.data.data;
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title:
                  response.data.message || "Error in getting Announcements",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error in getting all announcements ", err);
          this.toast({
            component: ToastificationContent,
            props: {
              title:
                response.data.message ||
                "Server Error in getting Announcements",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
  },
  beforeMount() {
    this.getAllAnnouncements();
  },
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
