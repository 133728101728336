<template>
  <b-card no-body>
    <b-card-header class="email-detail-head">
      <div
        class="
          user-details
          d-flex
          justify-content-between
          align-items-center
          flex-wrap
        "
      >
        <b-avatar
          size="48"
          :src="FILESURL + message.user_profile_image"
          class="mr-75"
        />
        <div class="mail-items">
          <h5 class="mb-0">
            {{ message.from_name }}
          </h5>
          <span class="font-small-3 text-muted mr-25">{{
            message.user_email
          }}</span>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted">{{
          formatDate(message.created_at)
        }}</small>
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper pt-2">
      <div class="mail-message" v-html="message.message" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BAvatar,
  BLink,
  BImg,
} from "bootstrap-vue";
import { formatDate } from "@core/utils/filter";
import { FILESURL } from "@/config";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BLink,
    BImg,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      FILESURL,
    };
  },
  setup() {
    return {
      formatDate,
    };
  },
};
</script>

<style>
</style>
