<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="Compose Email"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <template #modal-header>
      <h5 class="modal-title">Compose Announcement</h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <template #modal-footer>
      <div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="sendEmail"
        >
          Send
        </b-button>
      </div>
    </template>

    <b-form>
      <div class="compose-mail-form-field">
        <label for="email-to" class="form-label">To: </label>
        <v-select
          v-model="composeData.to"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="fullName"
          class="flex-grow-1 email-to-selector"
          :close-on-select="false"
          :options="emailToOptions"
          input-id="email-to"
        >
          <template #option="{ user_profile_image, fullName }">
            <b-avatar size="sm" :src="FILESURL + user_profile_image" />
            <span class="ml-50"> {{ fullName }}</span>
          </template>

          <template #selected-option="{ user_profile_image, fullName }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="FILESURL + user_profile_image"
            />
            <span class="ml-50"> {{ fullName }}</span>
          </template>
        </v-select>
      </div>

      <div class="compose-mail-form-field">
        <label for="email-subject">Subject: </label>
        <b-form-input id="email-subject" v-model="composeData.subject" />
      </div>

      <div class="message-editor">
        <quill-editor
          id="quil-content"
          v-model="composeData.message"
          :options="editorOption"
        />
        <div id="quill-toolbar" class="d-flex border-bottom-0">
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-link" />
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BAvatar,
  BDropdownDivider,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CommonServices from "@/apiServices/CommonServices";
import { FILESURL } from "@/config";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,
    BButton,

    // 3rd Party
    quillEditor,
    vSelect,
  },
  model: {
    prop: "shallShowEmailComposeModal",
    event: "update:shall-show-email-compose-modal",
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      emailToOptions: [],
      FILESURL,
    };
  },
  setup(_, { emit }) {
    const composeData = ref({});
    const showCcField = ref(false);
    const showBccField = ref(false);

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar",
      },
      placeholder: "Message",
    };

    const sendEmail = () => {
      // composeData.value = {};
      const user_ids = [];
      const user_type_ids = [];

      const to = composeData.value.to;

      for (let i = 0; i < to.length; i++) {
        const item = to[i];
        if (item.user_id) {
          user_ids.push(item.user_id);
        } else if (item.user_type_id) {
          user_type_ids.push(item.user_type_id);
        }
      }

      const data = {
        user_ids,
        user_type_ids,
        subject: composeData.value.subject,
        message: composeData.value.message,
      };

      CommonServices.makeAnnouncement(data)
        .then((response) => {
          if (response.data.status) {
            emit("update:shall-show-email-compose-modal", false);
            emit("reload-list");
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Error in making Announcement",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error in making ", err);
          this.toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || "Error in making Announcement",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });

      emit("update:shall-show-email-compose-modal", false);
      // ? Send your Email
    };

    const discardEmail = () => {
      composeData.value = {};
      emit("update:shall-show-email-compose-modal", false);
    };

    return {
      composeData,
      editorOption,
      showCcField,
      showBccField,

      // Email actions
      sendEmail,
      discardEmail,
    };
  },
  methods: {
    getUsers() {
      CommonServices.getAllUsers()
        .then((response) => {
          if (response.data.status) {
            this.emailToOptions = response.data.data;
          }
        })
        .catch((err) => {
          console.log("Error in getting getAllUsers", err);
        });
    },
  },
  beforeMount() {
    this.getUsers();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
form ::v-deep {
  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
