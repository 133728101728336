<template>
  <div class="email-app-details">
    <!-- Email Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="
              $store.state.appConfig.isRTL
                ? 'ChevronRightIcon'
                : 'ChevronLeftIcon'
            "
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ emailViewData.subject }}
        </h4>
      </div>
    </div>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >
      <b-row>
        <b-col cols="12">
          <div class="email-label">
            <!-- <b-badge
              v-for="label in emailViewData.labels"
              :key="label"
              pill
              class="text-capitalize mr-50"
              :variant="`light-${resolveLabelColor(label)}`"
            >
              {{ label }}
            </b-badge> -->
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <email-message-card :message="emailViewData" />
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BBadge,
  BCard,
  BLink,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ref, watch } from "@vue/composition-api";
import useEmail from "./useEmail";
import EmailMessageCard from "./EmailMessageCard.vue";

export default {
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    EmailMessageCard,
  },
  props: {
    emailViewData: {
      type: Object,
      required: true,
    },
    opendedEmailMeta: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveLabelColor } = useEmail();

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const showWholeThread = ref(false);

    watch(
      () => props.emailViewData.id,
      () => {
        showWholeThread.value = false;
      }
    );

    return {
      // UI
      perfectScrollbarSettings,
      showWholeThread,

      // useEmail
      resolveLabelColor,
    };
  },
};
</script>

<style>
</style>
