<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="
                $emit('update:shall-show-email-compose-modal', true);
                $emit('close-left-sidebar');
              "
            >
              Compose
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <b-list-group style="cursor: pointer" class="list-group-messages">
              <b-list-group-item
                :active="selectedFilter === 'inbox'"
                @click="selectFilter('inbox')"
              >
                <feather-icon :icon="'MailIcon'" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">Inbox</span>
              </b-list-group-item>
              <b-list-group-item
                :active="selectedFilter === 'sent'"
                @click="selectFilter('sent')"
              >
                <feather-icon :icon="'SendIcon'" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">Sent</span>
              </b-list-group-item>
            </b-list-group>

            <!-- <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="label in emailLabel"
                :key="label.title + $route.path"
                :to="label.route"
                :active="isDynamicRouteActive(label.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${label.color}`"
                />
                <span>{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group> -->
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BButton, BListGroup, BListGroupItem, BBadge } from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    emailsMeta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedFilter: "inbox",
    };
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    return {
      perfectScrollbarSettings,
    };
  },
  methods: {
    selectFilter(filterName) {
      this.selectedFilter = filterName;
      this.$emit('close-left-sidebar')
      this.$emit('filter-selected', filterName)
    },
  },
};
</script>

<style>
</style>
